import React from "react";
import "../components/bootstrap.min.css";
import "../components/vendor/tether/tether.min.css";
import "../components/vendor/bootstrap/css/bootstrap.min.css";
import "../components/css/fonts/express-icons.css";
import "../components/css/card.css";

import "../components/vendor/font-awesome/css/font-awesome.min.css";
import "../components/vendor/bootstrap/css/glyphicon.css";
import "../components/vendor/cubeportfolio/css/cubeportfolio.min.css";

//import '../components/vendor/ion-icons/css/ionicons.min.css',
import "../components/vendor/owl-carousel/owl.theme.css";
import "../components/vendor/owl-carousel/owl.carousel.css";
import "../components/vendor/magnific-popup/magnific-popup.css";

//Theme CSS
import "../components/css/main.css";
import "../components/css/main-shortcodes.css";
import "../components/css/header.css";
import "../components/css/form-element.css";
import "../components/css/animation.css";
import "../components/css/bg-stylish.css";
import "../components/css/blog.css";
import "../components/css/streamline-icon.css";
import "../components/css/responsive.css";
import "../components/css/utilities.css";
import "../components/css/portfolio.css";
import "../components/css/font-icons.css";
import "../components/css/skins/default.css";
//Theme Custom CSS
import "../components/css/custom.css";
import Footer from "../components/footer";
import Header from "../components/header";

import TextLoop from "react-text-loop";

const IndexPage = props => {
  //const users = props.data.allPublishedTemplates.edges;
  return (
    <React.Fragment>
      <div class="wrapper">
        <Header />
        <div class="page">
          <div class="page-inner p-none">
            <section class="section-primary pb-30 pix-business-5">
              <div class="container" style={{ marginTop: 0 }}>
                <div class="row text-white text-center">
                  <div
                    class="col-md-12"
                    style={{ paddingLeft: 50, paddingRight: 50 }}
                  >
                    <h2 class="section-title fs-40 pt-10 mb-10 fw-5">
                      Create, Manage and Publish your{" "}
                      <TextLoop style={{ width: "200px" }}>
                        <span style={{ color: "yellow" }}>Website</span>
                        <span style={{ color: "yellow" }}>Video </span>
                        <span style={{ color: "yellow" }}>
                          {" "}
                          Motion graphic{" "}
                        </span>
                        <span style={{ color: "yellow" }}>Flyer</span>
                        <span style={{ color: "yellow" }}>Logo</span>
                        <span style={{ color: "yellow" }}>Ads</span>
                        <span style={{ color: "yellow" }}>digital assets</span>
                      </TextLoop>{" "}
                      in minutes{" "}
                    </h2>
                    <p class="section-sub-title o-9 text-white">
                      Outsource to a professional or search through thousands of
                      templates to get started.
                    </p>
                  </div>

                  <div class="col-md-10 offset-md-1">
                    <div class="clearfix mt-40">
                      <a
                        href={`${process.env.GATSBY_API_URL}/creators`}
                        class="btn btn-primary item-shadow-5x btn-rounded"
                      >
                        <i class="fa fa-check-circle" /> Get Started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class=" b-bordered">
              <div class="container">
                <div class="col-md-12 text-center">
                  <h2 class="fs-30 fw-6 mb-15">What do you want to do?</h2>
                </div>

                <div class="row mb-40 text-center">
                  <div class="col-md-2 col-sm-6">
                    <div class="team-member">
                      <div class="member-photo">
                        <img
                          class="img-responsive"
                          src="img/website.png"
                          alt="Express"
                        />
                        <div class="member-social">
                          <div class="member-social-inner">
                            <ul class="list-inline">
                              <button className="btn btn-primary">
                                {" "}
                                Open{" "}
                              </button>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="member-info">
                        <div class="member-name">
                          <h3 class="name tt-none">Website</h3>
                        </div>
                        <div class="member-position">
                          <span>
                            Create and Host all types of websites from 1000's of
                            available template.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-6">
                    <div class="team-member">
                      <div class="member-photo">
                        <img class="img-responsive" src="img/video.png" />
                        <div class="member-social">
                          <div class="member-social-inner">
                            <ul class="list-inline">
                              <button className="btn btn-primary">
                                {" "}
                                Open{" "}
                              </button>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="member-info">
                        <div class="member-name">
                          <h3 class="name tt-none">Video</h3>
                        </div>
                        <div class="member-position">
                          <span>Clip, Add subtitles, Rotate</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-6">
                    <div class="team-member">
                      <div class="member-photo">
                        <img
                          class="img-responsive"
                          src="img/editor.png"
                          alt="Express"
                        />
                        <div class="member-social">
                          <div class="member-social-inner">
                            <ul class="list-inline">
                              <button className="btn btn-primary">
                                {" "}
                                Open{" "}
                              </button>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="member-info">
                        <div class="member-name">
                          <h3 class="name tt-none">Graphics</h3>
                        </div>
                        <div class="member-position">
                          <span>Create poster, social media post e.t.c</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-6">
                    <div class="team-member">
                      <div class="member-photo">
                        <img
                          class="img-responsive"
                          src="img/image.png"
                          alt="Express"
                        />
                        <div class="member-social">
                          <div class="member-social-inner">
                            <ul class="list-inline">
                              <button className="btn btn-primary">
                                {" "}
                                Open{" "}
                              </button>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="member-info">
                        <div class="member-name">
                          <h3 class="name tt-none">Image Editor</h3>
                        </div>
                        <div class="member-position">
                          <span>Crop, rotate, resize your images</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-6">
                    <div class="team-member">
                      <div class="member-photo">
                        <img
                          class="img-responsive"
                          src="img/animation.png"
                          alt="Express"
                        />
                        <div class="member-social">
                          <div class="member-social-inner">
                            <ul class="list-inline">
                              <button className="btn btn-primary">
                                {" "}
                                Open{" "}
                              </button>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="member-info">
                        <div class="member-name">
                          <h3 class="name tt-none">Motion</h3>
                        </div>
                        <div class="member-position">
                          <span>Create animated ads, slideshows ...</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-6">
                    <div class="team-member">
                      <div class="member-photo">
                        <img
                          class="img-responsive"
                          src="img/cloud.png"
                          alt="Express"
                        />
                        <div class="member-social">
                          <div class="member-social-inner">
                            <ul class="list-inline">
                              <button className="btn btn-primary">
                                {" "}
                                Open{" "}
                              </button>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="member-info">
                        <div class="member-name">
                          <h3 class="name tt-none">Manage digital assets</h3>
                        </div>
                        <div class="member-position">
                          <span>Upload and manager your digital assets</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div>
        <section class=" alternate-color b-bordered">
          <div class="col-md-12 text-center">
            <h4 class="fs-30 fw-4 mb-15">How it works</h4>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-md-3 col-sm-6">
                <div class="feature-box text-center p-15">
                  <div class=" mb-20">
                    <img
                      src="img/question.png"
                      width="80"
                      class="rounded-circle"
                    />
                  </div>
                  <div class="content">
                    <h3 class="title fw-6 mb-20">1. Decide</h3>

                    <p class="detail">Decide what you want to create</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6">
                <div class="feature-box text-center p-15">
                  <div class=" mb-20">
                    <img
                      src="img/search.png"
                      width="80"
                      class="rounded-circle"
                    />
                  </div>
                  <div class="content">
                    <h3 class="title fw-6 mb-20">2. Search</h3>
                    <p class="detail">
                      Search through 1000's of professional templates and select
                      one that suits you.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6">
                <div class="feature-box text-center p-15">
                  <div class=" mb-20">
                    <img
                      src="img/customize.png"
                      width="80"
                      class="rounded-circle"
                    />
                  </div>
                  <div class="content">
                    <h3 class="title fw-6 mb-20">3. Outsource </h3>
                    <p class="detail">
                      Determine what customization services you want and outsource your tasks to the template creator.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6">
                <div class="feature-box text-center p-15">
                  <div class=" mb-20">
                    <img
                      src="img/check.png"
                      width="80"
                      class="rounded-circle"
                    />
                  </div>
                  <div class="content">
                    <h3 class="title fw-6 mb-20">4. Publish</h3>
                    <p class="detail">
                      Publish online to your website or print.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="row text-black text-center">
        <div class="col-md-12">
          <div class="text-center">
            <h4 class="fs-30 fw-4 mb-15">Testimonials</h4>
          </div>
          <section class="section-primary main-color b-bordered">
            <div class="container">
              <div class="row">
                <div class="col-md-10 offset-md-1">
                  <div class="tab-wrap line-tab">
                    <div class="tab-content">
                      <div class="tab-pane active" id="Default" role="tabpanel">
                        <div class="testimonial-one text-center">
                          <blockquote class="pullquote">
                            <p class="fs-20">
                              Not only have we been amazed on how quick we could
                              generate professional looking graphics for our
                              businesss, but we found ourselves working with
                              great professionals who would give us exactly what
                              we were looking for.
                            </p>

                            <footer class="blockquote-footer fs-30">
                              Mrs Gitau.
                              <cite title="Source Title">Business Woman.</cite>
                            </footer>

                            <div class="testimonial-image">
                              <img src="img/avatars/person.jpeg" width="50" />
                            </div>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <section class=" alternate-color b-bordered">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h4 class="fs-30">Pricing</h4>
              </div>

              <div class="pricing pricing--rabten">
                <div class="pricing__item">
                  <div class="icon ion-cube"></div>
                  <h3 class="pricing__title">Small </h3>
                  <p class="pricing__sentence">2 teams</p>
                  <div class="pricing__price">
                    <span class="pricing__anim pricing__anim--1">
                      <span class="pricing__currency">$</span>59
                    </span>
                    <span class="pricing__anim pricing__anim--2">
                      <span class="pricing__period">per month</span>
                    </span>
                  </div>
                  <ul class="pricing__feature-list">
                    <li class="pricing__feature">10 GB of space</li>
                    <li class="pricing__feature">Best for small firms</li>
                  </ul>
                  <button class="pricing__action">Choose plan</button>
                </div>
                <div class="pricing__item">
                  <div class="icon ion-person-stalker"></div>
                  <h3 class="pricing__title">Professional</h3>
                  <p class="pricing__sentence">Up to 5 teams</p>
                  <div class="pricing__price">
                    <span class="pricing__anim pricing__anim--1">
                      <span class="pricing__currency">$</span>199
                    </span>
                    <span class="pricing__anim pricing__anim--2">
                      <span class="pricing__period">per month</span>
                    </span>
                  </div>
                  <ul class="pricing__feature-list">
                    <li class="pricing__feature">50 GB of space</li>
                    <li class="pricing__feature">Best for medium firms</li>
                  </ul>
                  <button class="pricing__action">Choose plan</button>
                </div>
                <div class="pricing__item">
                  <div class="icon ion-pie-graph"></div>
                  <h3 class="pricing__title">Organization</h3>
                  <p class="pricing__sentence">up to 20 teams</p>
                  <div class="pricing__price">
                    <span class="pricing__anim pricing__anim--1">
                      <span class="pricing__currency">$</span>499
                    </span>
                    <span class="pricing__anim pricing__anim--2">
                      <span class="pricing__period">per month</span>
                    </span>
                  </div>
                  <ul class="pricing__feature-list">
                    <li class="pricing__feature">500 GB space</li>
                    <li class="pricing__feature">
                      Best for agencies and large firms.{" "}
                    </li>
                  </ul>
                  <button class="pricing__action">Choose plan</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-tiny" style={{ backgroundColor: "grey" }}>
        <div class="row fw-row">
          <div class="xpromo-wrap col-sm-12">
            <div class="xpromo-bar promo-button">
              <p class="text-white fs-26 fw-6 lh-38">
                Get up to 1 GB storage for free
              </p>
              <a class="btn standard white mb-none ls-0" href="#">
                Click here
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};
export default IndexPage;

/*
export const query = graphql`
{
   allPublishedTemplates {
     edges {
       node {
         cover_image
         id
         size {
           width
           height
         }
       
       }
     }
   }
 }
`;
*/
